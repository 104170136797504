export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  locationsPage: {
    padding: '10px 5px',
    '.locationTitle': {
      fontSize: ['2rem', '', '', '3rem']
    },
    '.location': {
      border: 'solid 5px',
      borderColor: 'background'
    },

    '.logo': {
      left: ['50%', '', '1rem', '1rem'],
      transform: ['', '', 'unset'],
      width: ['100%', '', 'fit-content']
    }
  },

  navigation: {
    '.container': {
      backgroundColor: ['black', '', '', '#191919'],
      padding: ['0.5rem', '0.5rem', '1rem', '0rem'],
      zIndex: '1001',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'white',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.5rem', '0.5rem'],
      // width: '100%',
      width: 'fit-content',
      flexGrow: ['', '', '', '1'],
      margin: ['0rem 0rem 0.5rem', '0rem 0rem 1rem', '', '0rem'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      a: {
        fontSize: ['1rem', '1.25rem', '1.5rem', '1.15rem']
      },
      ':hover': {
        color: 'inherit'
      }
    },

    '.hamburger': {
      order: '2',
      '> div': {
        backgroundColor: ['primary', '', '', 'white']
      }
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['100px', '', '180px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['center', 'center', 'flex-start'],
      padding: ['2rem', '', '', '0rem'],
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.25rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneContainer': {
      width: 'fit-content',
      // filter: 'brightness(0) invert(1)',
      border: 'none',
      border: '2px solid',
      borderColor: 'primary',
      padding: '0.5rem',
      borderRadius: '1500px',
      svg: {
        path: {
          fill: ['white', '', '', 'primary']
        }
      }
    },
    '.addressContainer': {
      width: 'fit-content',
      border: 'none',
      // filter: 'brightness(0) invert(1)',
      border: '2px solid',
      borderColor: 'primary',
      padding: '0.5rem',
      borderRadius: '1500px',
      svg: {
        path: {
          fill: ['white', '', '', 'primary']
        }
      }
    },
    '.socialIconsContainer': {
      width: 'fit-content',
      filter: 'brightness(0) invert(1)'
    },

    '.socialContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        border: 'none',
        border: '2px solid',
        borderColor: 'primary',
        padding: '0.5rem',
        borderRadius: '1500px',
        margin: '0rem 0.25rem'
      },
      svg: {
        margin: '0rem',
        height: '20px',
        width: '20px'
      }
    },

    '.containerScrolled': {
      backgroundColor: ['black', '', '', '#191919'],
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      height: '75px',
      '.hamburger > div': {
        // backgroundColor: '#c6a34d',
      }
    },
    '.logo': {
      order: '1',
      // position: ['static', '', ''],
      left: ['50%'],
      top: '0.25rem',
      transform: 'translateX(-50%)',
      img: {
        opacity: '1',
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['80px', '', '150px', '']
      }
    },
    '.logoScrolled': {
      order: '1',
      left: ['50%', '', '', '1rem'],
      top: '5px',
      transform: ['translateX(-50%)', '', '', 'unset'],
      img: {
        opacity: '1',
        maxHeight: ['60px', '', '100px', '']
      }
    },

    '.logo': {
      order: '1',
      left: ['50%', '', '', '1rem'],
      top: '5px',
      transform: ['translateX(-50%)', '', '', 'unset'],
      img: {
        opacity: '1',
        maxHeight: ['75px', '', '150px', '']
      }
    },

    '.locationSwitcher': {
      // // position:''
      // top: 'unset',
      // bottom: '0rem',
      // left: 'unset',
      // right: '1rem',
      '.container': {
        backgroundColor: 'black',
        border: 'none'
      },
      padding: ['1rem 0rem', '', '', '0rem'],
      top: '0rem',
      left: '0rem',
      transform: 'unset',
      '.button': {
        fontSize: ['0.6rem', '', '', '0.7rem']
      }
    },

    '.popUp': {
      '.logo': {
        order: 'unset',
        transform: 'unset'
      },
      '.locations': {
        a: {
          variant: 'buttons.secondary'
        }
      }
    }
  },

  footer: {
    marginTop: '5rem',
    borderTop: '1px solid',
    borderColor: 'secondary',
    backgroundColor: 'black',
    color: 'white',
    padding: '2rem 1rem 8rem',
    paddingBottom: ['4rem', '', '', '100px'],
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'primary'
        }
      }
    },
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: '5px solid',
    borderColor: 'primary',
    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.poweredByText': {
      margin: 'auto'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      fontFamily: 'sans-serif'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start'],
      borderTop: 'solid 2px',
      borderBottom: 'solid 2px',
      borderColor: 'primary'
    },
    '.socialContainer': {
      svg: {
        margin: '0.5rem',
        width: '40px',
        height: '40px',
        padding: '0.5rem',
        border: 'solid 1px',
        borderColor: 'primary',
        borderRadius: '1100px',
        path: {
          fill: 'primary'
        }
      }
    }
  },

  ctaWidget: {
    display: 'none!important',
    // bottom: '6.5rem',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    order: '2'
  },

  subtitle: {
    color: 'primary',
    fontWeight: 'normal',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3',
    fontSize: '1.1rem'
  },

  sideBySide1: {
    overflow: 'hidden',
    padding: '1rem',
    '.content': {
      // variant: 'customVariants.forkGraphics',
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text',
        textAlign: 'left',
        p: {
          textAlign: 'left'
        }
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '55%'],

      height: ['', '', '80vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        border: ['solid 3px', 'solid 3px', 'solid 5px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    padding: '1rem',
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    paddingRight: ['', '', '1rem'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      padding: ['1rem 0rem', '', '0rem'],
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 9px',
        borderColor: 'primary',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%'],
        border: ['solid 5px', 'solid 9px', 'solid 9px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%'],
        border: ['solid 5px', 'solid 9px', 'solid 9px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      }
    },

    '.content': {
      // variant: 'customVariants.forkGraphics',
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem 1rem', '', '', '3rem 3.5rem', '4rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      backgroundColor: '#363636',
      margin: ['', '', 'auto 2rem auto auto'],
      color: 'white',
      height: 'fit-content',

      '.title': {
        variant: 'customVariants.title',
        color: 'inherit'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text',
        p: {
          color: 'inherit'
        },
        color: 'inherit'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  sideBySideParallax: {
    boxShadow: '30px -30px 0 0px #d6941f',
    margin: ['3rem 1rem', '', '3rem', '4rem'],
    padding: '0rem',
    width: 'calc(100% -2rem)',
    height: ['', '', '60vh', '60vh'],
    backgroundPosition: ['center 100%', '', 'center center'],
    backgroundSize: ['150%', '', 'cover'],
    '.section': {
      // variant: 'customVariants.forkGraphics',
      width: ['95%', '', '55%', '40%'],
      maxWidth: 'unset',
      margin: ['auto', '', '0rem auto auto 3rem'],
      backgroundColor: 'white',
      padding: ['2rem 1rem', '', '3rem'],
      marginBottom: ['100%', '', '0rem'],
      backgroundColor: '#420e0e',
      color: 'white',
      position: 'relative',
      top: ['2rem', '', '5rem'],
      border: 'solid 10px #bb351b'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'inherit'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: 'inherit',

      p: {
        color: 'inherit',
        textAlign: 'center'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '50%'],
      maxWidth: 'unset',
      margin: 'auto ',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '2.5rem', '3.5rem', '5.5rem'],
      textShadow: '-7px 7px black',
      color: 'light'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textShadow: '3px 3px black'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',

      p: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textShadow: '1px 1px black'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '400px'],
      order: ['3', '', 'unset']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover'
    },
    '.content': {
      backgroundColor: ['', '', 'transparent'],
      color: 'text',
      margin: ['', '', '2rem 1rem'],
      width: ['100%', '100%', '60%'],
      border: 'solid 2px',
      borderColor: 'secondary'
      // padding: ['', '', '0.5rem 5rem 0.5rem 2rem'],
    },
    '.text': {
      lineHeight: '1.5',
      color: ['primary', '', 'primary'],
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      letterSpacing: '1px',
      fontFamily: 'display',
      marginBottom: '2rem',
      fontWeight: 'bold'
    },
    '.title': {
      // width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: ['text', '', 'text'],
      letterSpacing: '3px',
      marginBottom: '2rem'
    },
    '.date': {
      color: ['text', '', 'text']
      // display: 'none',
    },

    '.shoutCTA': {
      variant: 'buttons.secondary'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySideParallax'
  },

  homepageMenu: {
    padding: '5vh 1rem',

    // overflowX: 'hidden',
    // overflowY: 'visible',
    flexDirection: ['', '', 'row'],
    paddingRight: ['', '', '1rem'],
    borderRight: '75px solid #192325',
    marginTop: '10vh',
    marginBottom: '10vh',
    backgroundColor: 'primary',
    '.lazyload-wrapper': {
      width: ['', '', '40%'],
      padding: ['1rem', '', '0rem'],
      position: 'relative',
      order: ['', '', '2'],
      img: {
        position: ['static', '', 'absolute'],
        left: '-10%',
        top: '50%',
        transform: ['', '', 'translateY(-50%)'],
        width: ['120%', '', '175%'],
        height: ['120%', '', '175%'],
        objectFit: 'contain',
        maxWidth: 'unset'
      }
    },
    '.content': {
      // variant: 'customVariants.forkGraphics',
      zIndex: '10',

      width: ['', '', '50%'],
      padding: ['2rem 1rem', '', '', '3rem 3.5rem', '4rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      backgroundColor: 'transparent',
      margin: ['', '', 'auto auto auto 2rem'],
      color: 'white',
      height: 'fit-content',

      '.title': {
        variant: 'customVariants.title',
        color: 'inherit'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: 'secondary'
      },
      '.text': {
        variant: 'customVariants.text',
        p: {
          color: 'inherit'
        },
        color: 'inherit'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    variant: 'customVariants.sideBySideParallax'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '4rem 1rem', '4rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.text': {
      fontSize: '1.2rem'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['1rem', '', '1rem'],
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    backgroundColor: '#dfd3bf',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    border: ['solid 5px', 'solid 10px'],

    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      maxWidth: '1200px',
      margin: '0rem auto',
      // flexDirection: 'row-reverse',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'View The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        borderBottom: '3px solid',
        order: 'unset'
      },
      '.menuTab': {
        fontSize: '1.1rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: '1rem 0.25rem 0rem'
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 1.5rem',
      fontSize: ['1.2rem', '1.3rem', '1.74rem', '2rem'],
      width: 'fit-content',
      borderBottom: 'solid 1px',
      borderColor: 'secondary',
      color: 'secondary',
      padding: '1rem 0rem'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      // opacity: '0.75',
      fontSize: '0.9rem',
      color: 'secondary'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      fontSize: ['1.3rem', '1.3rem', '1.3rem', '1.4rem'],
      color: 'primary',
      fontWeight: 'normal',
      textTransform: 'capitalize'
    },
    '.menuItemDescription': {
      color: 'black',
      fontSize: '1.2rem',
      opacity: '0.8'
    },
    '.menuItemPrice': {
      color: 'secondary',
      fontSize: '1.1rem'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.container': {
      padding: '0rem'
    },
    '.title': {
      backgroundColor: 'secondary',
      padding: '2rem 1rem',
      textTransform: 'uppercase',
      fontSize: ['2rem', '2.5rem', '3.5rem'],
      color: 'black'
    },
    '.events-container': {
      maxWidth: 'unset'
    },

    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      height: '100%',
      width: '100%',
      maxHeight: '350px'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },

    '.eventItemContent': {
      width: '100%'
    },

    '.eventItemTitle': {
      borderTop: '1px solid',
      marginBottom: '1.25rem',
      paddingTop: '1rem',
      fontSize: ['1.5rem', '', '2.5rem']
    },

    '.eventItemContainer': {
      flexDirection: 'column',
      width: ['100%', 'calc(50% - 4%)'],
      border: 'solid 5px',
      borderColor: 'secondary'
    },
    '.eventItemDescription': {
      color: 'secondary'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'light',
    '.content_container': {
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'secondary',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Private Parties  ====
  // ? ========================
  privatePartiesButtons: {
    '.title': {
      display: 'none'
    },
    '.box': {
      alignItems: 'center'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: ' auto',
      fontWeight: 'bold',
      padding: '4rem 2rem',
      fontWeight: 'normal',
      letterSpacing: '1px',
      fontSize: '1.5rem',
      textAlign: 'center'
    }
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(50% - 2rem * 2)', 'calc(50% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'black',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'black'
    },
    '.tagPill': {
      color: 'white',
      padding: '0.5rem',
      margin: '0rem 0.5rem 0.5rem',
      fontSize: '0.8rem',
      backgroundColor: 'primary',
      borderRadius: '50px'
    },
    '.tagsContainer': {
      color: 'black'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
